<template>
  <div class="search-form">
    <div class="search-row">
      <div class="search-group w-100">
        <label>Тип недвижимости</label>
        <multi_select :data="data_info.commercial_type" :hide_filter="'yes'" v-model="search_params['commercial_type']" @input="updateCount" />
      </div>
      <div class="search-group price-search">
        <label>Стоимость, ₽</label>
        <div class="from-to">
          <input v-debounce:600ms="updateCount" type="text" ref="from" v-model="search_params['price_from']" class="from-input" placeholder="От" v-currency="v_options" />
          <input v-debounce:600ms="updateCount" type="text" ref="from" v-model="search_params['price_to']" class="to-input" placeholder="До"  v-currency="v_options" />
        </div>
      </div>
      <div class="search-group area-search">
        <label>Площадь, м2</label>
        <div class="from-to">
          <input v-debounce:600ms="updateCount" type="text" ref="from" v-model="search_params['s_all_from']" class="from-input" placeholder="От" v-currency="v_options" />
          <input v-debounce:600ms="updateCount" type="text" ref="from" v-model="search_params['s_all_to']" class="to-input" placeholder="До"  v-currency="v_options" />
        </div>
      </div>
      <div class="search-group w-100">
        <label>Нас. пункт</label>
        <multi_select :data="search_data.locations.commercial" v-model="search_params['locations']" @input="updateCount"/>
      </div>
      <div class="search-group w-100">
        <label>Состояние</label>
        <multi_select :data="data_info.commercial_conditions" :hide_filter="'yes'" v-model="search_params['commercial_conditions']" @input="updateCount" />
      </div>
    </div>
    <div class="search-row">
      <div class="search-group">
        <input v-debounce:600ms="updateCount" v-model="search_params['id']" type="text" class="string-input offer_id" placeholder="Код объекта">
      </div>
      <div class="search-group">
        <single_select v-model="search_params['sort']" :options="[{title:'Сортировка',val:null},{title:'Сначала дешевле',val:'price_up'},{title:'Сначала дороже',val:'price_down'}]" />
      </div>
      <div class="w-100 frc">
        <button class="btn btn-danger m-l-20" @click="clear">Очистить</button>
        <button class="btn btn-primary m-l-20" @click="goToResults">{{data_info.searchButtonCount(count)}}</button>
        <filter-button @clack="showAdvanced"/>
      </div>
    </div>

    <modal name="commercial-advanced" :width="900" :height="'auto'" :styles="'overflow: inherit'">

      <div class="modal-wrapper">
        <div class="modal-body">

          <div class="row">
            <div class="col-sm-4">
              <div class="search-group">
                <label>Этаж</label>
                <div class="from-to">
                  <input v-debounce:600ms="updateCount" type="text" ref="from" v-model="search_params['floor_from']" class="from-input" placeholder="От" v-currency="v_options" />
                  <input v-debounce:600ms="updateCount" type="text" ref="from" v-model="search_params['floor_to']" class="to-input" placeholder="До"  v-currency="v_options" />
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="search-group">
                <label>Этажей в доме</label>
                <div class="from-to">
                  <input v-debounce:600ms="updateCount" type="text" ref="from" v-model="search_params['total_floors_from']" class="from-input" placeholder="От" v-currency="v_options" />
                  <input v-debounce:600ms="updateCount" type="text" ref="from" v-model="search_params['total_floors_to']" class="to-input" placeholder="До"  v-currency="v_options" />
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="search-group w-100">
                <label>Вход</label>
                <multi_select :data="data_info.commercial_entrance" :hide_filter="'yes'" v-model="search_params['commercial_entrance']" @input="updateCount" />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-4">
              <div class="search-group w-100">
                <label>Туалет</label>
                <multi_select :data="data_info.commercial_wc" :hide_filter="'yes'" v-model="search_params['commercial_wc']" @input="updateCount" />
              </div>
            </div>
            <div class="col-sm-4">
              <div class="search-group w-100">
                <label>Фасад</label>
                <multi_select :data="data_info.commercial_facade" :hide_filter="'yes'" v-model="search_params['commercial_facade']" @input="updateCount" />
              </div>
            </div>
          </div>

        </div>

        <div class="modal-bottom">
          <div class="w-100 frc">
            <button class="btn btn-primary m-l-20" @click="goToResults">{{data_info.searchButtonCount(count)}}</button>
            <button class="btn btn-danger" @click="clear">Очистить</button>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
  import info from "../data"
  import from_to from '../inputs/from_to.vue';
  import multi_select from '../inputs/multi_select.vue';
  import filterButton from '../inputs/filter_button.vue'
  import flats_input from "../inputs/flats_input.vue";
  import _ from "lodash";
  import single_select from "@/components/front/search/inputs/single_select.vue";
  export default {
    props: ['counts','gon_search'],
    components: {single_select, flats_input,from_to,multi_select,filterButton},
    data: function () {
      return {
        search_params: {scope:'commercial'},
        search_data: {
          locations:[],
          streets: [],
          nbs: [],
        },
        data_info: {},
        count: null,
        v_options: {
          currency:null,
          locale:'sv-SE',
          precision: 0,
          allowNegative: false,
          distractionFree: false
        }
      }
    },
    created() {
      this.count = this.counts.commercial
      this.data_info = info
    },
    mounted() {
      this.search_data = gon.search_data
      if (this.gon_search !== null && this.gon_search['scope'] === 'commercial') {
        this.search_params = this.gon_search
      }
    },
    methods: {
      clear() {
        this.search_params = {scope:'commercial'};
        this.updateCount();
      },
      setupParams(data) {
        //console.log(data)
        this.search_params[data.name] = data.val;
        this.updateCount();
      },
      showAdvanced() {
        this.$modal.show('commercial-advanced');
      },
      goToResults() {
        var strJSON = btoa(encodeURIComponent(JSON.stringify(this.search_params)));
        var uri = `/realty?s=${strJSON}`;
        window.open(uri,'_self');
      },
      updateCount: _.debounce(function debounceRead() {
        var strJSON = encodeURIComponent(JSON.stringify(this.search_params));
        let _this = this;
        const headers = {'X-Requested-With' : 'XMLHttpRequest','Content-Type': 'application/json'};
        fetch(`/counts?s=${strJSON}`, {
          method: 'GET',
          headers:headers
        })
            .then(response => response.json())
            .then(function(data) {
              _this.count = data
            })
            .catch((error) => {
              _this.error = error;
              _this.saving = false;
            });
      }, 500)
    }
  }
</script>

<style lang="scss">

</style>
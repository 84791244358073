
<div class="single-select">
  <div class="selected-block" @click="toggle">
    <span class="selected-txt">
      {{title}}
    </span>
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" :class="opened ? 'rotated' : ''">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M5.21967 9.21967C4.92678 9.51256 4.92678 9.98744 5.21967 10.2803L11.4697 16.5303C11.7626 16.8232 12.2374 16.8232 12.5303 16.5303L18.7803 10.2803C19.0732 9.98744 19.0732 9.51256 18.7803 9.21967C18.4874 8.92678 18.0126 8.92678 17.7197 9.21967L12 14.9393L6.28033 9.21967C5.98744 8.92678 5.51256 8.92678 5.21967 9.21967Z" fill="#4F4F4F"/>
    </svg>
  </div>
  <div class="select-list" v-click-outside="hide" v-show="opened">
    <div class="select-items">
      <div v-for="(item,index) in options" class="select-item" @click="toggleSelect(item)" :class="value === item.val ? 'selected' : '' ">
        {{item.title}}
      </div>
    </div>
  </div>
</div>

<template>
  <div class="full-screen-photos" @keydown.esc="close" tabindex="0">
    <div class="photo-gallery">
      <div class="big-photo">
        <div class="slide-control left-control" v-if="isCanPrev">
          <img src="/front/circle-arrow-left.png" @click="prevPhoto">
        </div>
        <img :src="currentPhoto" />
        <div class="slide-control right-control" v-if="isCanNext">
          <img src="/front/circle-arrow-right.png" @click="nextPhoto">
        </div>
      </div>
      <div class="thumbnails">
        <img :src="image.thumb" v-for="(image,index) in images" style="height:50px;" @click="selectPhoto(index)" :class="index === current_index ? 'selected' : ''">
      </div>
      <div class="photo-counter">
        {{current_index+1}} / {{images.length}}
      </div>
    </div>
    <div class="agent-block">
      <div class="m-b-15" style="display: flex;justify-content: space-between">
        <span class="agent-title">{{offer_info.title}}</span>
        <span class="close-button" @click="close">✕</span>
      </div>
      <div class="m-b-25">{{offer_info.address}}</div>
      <div class="m-b-15">{{offer_info.human_price}}<span class="sqr-price">{{offer_info.sqr_price}}</span></div>
      <agent :agent="offer_info.agent" />
    </div>

  </div>
</template>

<script>
  import agent from '../agent/agent.vue'
  export default {
    props: ['images','offer_info','start_index'],
    components: {agent},
    data: function() {
      return {
        current_index: 0
      }
    },
    created() {
      this.current_index = this.start_index;
    },
    methods: {
      close() {
        this.$emit('close',{});
      },
      nextPhoto() {
        if (this.isCanNext) {
          this.current_index += 1
          document.querySelectorAll('.thumbnails img')[this.current_index].scrollIntoView({inline: "center", behavior: "smooth"})
        }
      },
      prevPhoto() {
        if (this.isCanPrev) {
          this.current_index -= 1
          document.querySelectorAll('.thumbnails img')[this.current_index].scrollIntoView({inline: "center", behavior: "smooth"})
        }
      },
      selectPhoto(index) {
        this.current_index = index
        document.querySelectorAll('.thumbnails img')[this.current_index].scrollIntoView({inline: "center", behavior: "smooth"})
      }
    },
    computed: {
      currentPhoto() {
        return this.images[this.current_index].full
      },
      isCanNext() {
        return this.current_index+1 < this.images.length
      },
      isCanPrev() {
        return this.current_index > 0
      }
    }
  }
</script>

<style lang="scss" scoped>

  .m-b-15 {margin-bottom: 15px;}
  .m-b-25 {margin-bottom: 25px;}

  .full-screen-photos {
    position: fixed;
    top:0;
    left:0;
    z-index: 9999998;
    width: 100%;
    height: 100%;
    outline:0;
    display: flex;
    justify-content: space-between;
    background-color: #000000;
  }

  .photo-gallery {
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    img {
      border-radius: 10px;
    }
  }

  .big-photo {
    height: calc(100vh - 100px);
    display: flex;
    justify-content: center;
    position: relative;
    user-select: none;
    .left-control {
      left: 20px;
    }

    .right-control {
      right: 20px;
    }

    img {
      max-width: 100%;
      max-height: 100%;
      border-radius: 0;
    }
  }

  .agent-block {
    width: 340px;
    padding: 20px;
    background-color: white;
  }

  .thumbnails {
    padding: 10px;
    width: 100%;
    overflow: hidden;
    //height: 60px;
    display: flex;
    img {
      border: 3px solid transparent;
      cursor: pointer;
    }
    .selected {
      border: 3px solid #3939ff;
    }
  }

  .photo-counter {
    position: absolute;
    bottom: 70px;
    left: 15px;
    color: white;
    font-weight: bold;
    font-size: 20px;
  }

  .close-button {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background-color: #e2e3e3;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-weight: bold;
  }

  .agent-title {
    display: block;
    font-weight: normal;
    font-size: 16px;
    width: 180px;
  }

  .sqr-price {
    margin-left: 5px;
    font-size: 14px;
    color: grey;
  }

</style>
<template>
  <div class="search-form">
    <div class="search-row">
      <div class="search-group w-100">
        <label>Нас. пункт</label>
        <multi_select :data="search_data.locations.nbs" v-model="search_params['locations']" @input="updateCount" />
      </div>
      <div class="search-group">
        <label>Количество комнат</label>
        <flats_input v-model="search_params['rooms']" ref="flatsRooms" @input="updateCount" />
      </div>

      <div class="search-group price-search">
        <label>Стоимость, ₽</label>
        <div class="from-to">
          <input v-debounce:600ms="updateCount" type="text" ref="from" v-model="search_params['price_from']" class="from-input" placeholder="От" v-currency="v_options" />
          <input v-debounce:600ms="updateCount" type="text" ref="from" v-model="search_params['price_to']" class="to-input" placeholder="До"  v-currency="v_options" />
        </div>
      </div>
      <div class="search-group area-search">
        <label>Площадь, м2</label>
        <div class="from-to">
          <input v-debounce:600ms="updateCount" type="text" ref="from" v-model="search_params['s_all_from']" class="from-input" placeholder="От" v-currency="v_options" />
          <input v-debounce:600ms="updateCount" type="text" ref="from" v-model="search_params['s_all_to']" class="to-input" placeholder="До"  v-currency="v_options" />
        </div>
      </div>
      <div class="search-group w-100">
        <label>Жилой комплекс</label>
        <multi_select :data="search_data.nbs" v-model="search_params['jk']" @input="updateCount" />
      </div>
    </div>
    <div class="search-row">
      <input v-model="search_params['id']" type="text" class="string-input offer_id" placeholder="Код объекта">
      <div class="w-100 frc">
        <button class="btn btn-primary m-l-20" @click="goToResults">{{data_info.searchButtonCount(count)}}</button>
        <filter-button @clack="showAdvanced"/>
      </div>
    </div>

    <modal name="nb-advanced" :width="900" :height="'auto'" :styles="'overflow: inherit'">

      <div class="modal-wrapper">
        <div class="modal-body">

          <div class="row">
            <div class="col-sm-4">
              <div class="search-group">
                <label>Год постройки</label>
                <div class="from-to">
                  <input v-debounce:600ms="updateCount" type="text" ref="from" v-model="search_params['year_from']" class="from-input" placeholder="От" />
                  <input v-debounce:600ms="updateCount" type="text" ref="from" v-model="search_params['year_to']" class="to-input" placeholder="До" />
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="search-group">
                <label>Этаж</label>
                <div class="from-to">
                  <input v-debounce:600ms="updateCount" type="text" ref="from" v-model="search_params['floor_from']" class="from-input" placeholder="От" v-currency="v_options" />
                  <input v-debounce:600ms="updateCount" type="text" ref="from" v-model="search_params['floor_to']" class="to-input" placeholder="До"  v-currency="v_options" />
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="search-group">
                <label>Этажей в доме</label>
                <div class="from-to">
                  <input v-debounce:600ms="updateCount" type="text" ref="from" v-model="search_params['total_floors_from']" class="from-input" placeholder="От" v-currency="v_options" />
                  <input v-debounce:600ms="updateCount" type="text" ref="from" v-model="search_params['total_floors_to']" class="to-input" placeholder="До"  v-currency="v_options" />
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-4">
              <div class="search-group w-100">
                <label>Санузел</label>
                <multi_select :data="data_info.wc" :hide_filter="'yes'" v-model="search_params['wc']" @input="updateCount"/>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="search-group w-100">
                <label>Отопление</label>
                <multi_select :data="data_info.heating" :hide_filter="'yes'" v-model="search_params['heating']" @input="updateCount"/>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="search-group">
                <label>Площадь кухни</label>
                <div class="from-to">
                  <input v-debounce:600ms="updateCount" type="text" ref="from" v-model="search_params['s_k_from']" class="from-input" placeholder="От" v-currency="v_options" />
                  <input v-debounce:600ms="updateCount" type="text" ref="from" v-model="search_params['s_k_to']" class="to-input" placeholder="До"  v-currency="v_options" />
                </div>
              </div>
            </div>
          </div>

          <div class="modal-bottom">
            <div class="w-100 frc">
              <button class="btn btn-primary m-l-20">{{data_info.searchButtonCount(count)}}</button>
              <button class="btn btn-danger">Очистить</button>
            </div>
          </div>
        </div>
      </div>
    </modal>

  </div>
</template>

<script>
  import flats_input from "../inputs/flats_input.vue";
  import from_to from '../inputs/from_to.vue';
  import multi_select from '../inputs/multi_select.vue';
  import filterButton from '../inputs/filter_button.vue'
  import info from "../data";
  import _ from "lodash";

  export default {
    props: ['counts','gon_search'],
    components: {flats_input,from_to,multi_select,filterButton},
    data: function () {
      return {
        search_params: {scope:'nb'},
        search_data: {
          locations:[],
          streets: [],
          nbs: [],
        },
        data_info: {},
        count: null,
        v_options: {
          currency:null,
          locale:'sv-SE',
          precision: 0,
          allowNegative: false,
          distractionFree: false
        }
      }
    },
    created() {
      this.count = this.counts.nb;
      this.data_info = info;
    },
    mounted() {
      this.search_data = gon.search_data;
    },
    methods: {
      clear() {
        this.$refs.nbRooms.clear();
        this.search_params = {scope:'flats'};
        this.updateCount();
      },
      setupParams(data) {
        this.search_params[data.name] = data.val;
        this.updateCount();
      },
      showAdvanced() {
        this.$modal.show('nb-advanced');
      },
      goToResults() {
        var strJSON = btoa(encodeURIComponent(JSON.stringify(this.search_params)));
        var uri = `/realty?s=${strJSON}`;
        window.open(uri,'_self');
      },
      updateCount: _.debounce(function debounceRead() {
        var strJSON = encodeURIComponent(JSON.stringify(this.search_params));
        let _this = this;
        const headers = {'X-Requested-With' : 'XMLHttpRequest','Content-Type': 'application/json'};
        fetch(`/counts?s=${strJSON}`, {
          method: 'GET',
          headers:headers
        })
            .then(response => response.json())
            .then(function(data) {
              _this.count = data
            })
            .catch((error) => {
              _this.error = error;
              _this.saving = false;
            });
      }, 500)
    }
  }
</script>

<style>

</style>
<template>
  <div v-if="counts === null" class="front-mobile-search">
    <div class="text-center">
      Загрузка ...
    </div>
  </div>
  <div v-else class="front-mobile-search" :class="isShort ? 'short' : ''">
    <div class="m-search-tabs">
      <div class="m-search-tab" @click="changeTab('flats')">
        <span :class="current_tab==='flats' ? 'c-active' : 'c-inactive'">Квартиры</span>
        <div class="fake-border" :class="current_tab==='flats' ? 'active' : ''">&nbsp;</div>
      </div>
<!--      <div class="search-tab" @click="changeTab('nb')">-->
<!--        <span>Новостройки</span>-->
<!--        <div class="fake-border" :class="current_tab==='nb' ? 'active' : ''">&nbsp;</div>-->
<!--      </div>-->
      <div class="m-search-tab" @click="changeTab('houses')">
        <span :class="current_tab==='houses' ? 'c-active' : 'c-inactive'">Дома и участки</span>
        <div class="fake-border" :class="current_tab==='houses' ? 'active' : ''">&nbsp;</div>
      </div>
      <div class="m-search-tab" @click="changeTab('commercial')">
        <span :class="current_tab==='commercial' ? 'c-active' : 'c-inactive'">Коммерческая</span>
        <div class="fake-border" :class="current_tab==='commercial' ? 'active' : ''">&nbsp;</div>
      </div>
    </div>

    <flats-form v-show="current_tab === 'flats'" :counts="counts" :gon_search="gon_search"/>
<!--    <nb-form v-show="current_tab === 'nb'" :counts="counts" :gon_search="gon_search" />-->
    <houses-form v-show="current_tab === 'houses'" :counts="counts" :gon_search="gon_search" />
    <commercial-form v-show="current_tab === 'commercial'" :counts="counts" :gon_search="gon_search" />
    <div class="fcc m-t-20">
      <span class="btn btn-red" onclick="toggleMobileSearch()">закрыть</span>
    </div>
  </div>
</template>

<script>
  import flatsForm from './forms/flats.vue'
  import nbForm from './forms/nb.vue'
  import housesForm from './forms/houses.vue'
  import commercialForm from './forms/commercial.vue'
  export default {
    props: ['short_version'],
    components: {flatsForm,nbForm,housesForm,commercialForm},
    data: function () {
      return {
        current_tab:'flats',
        counts: null,
        gon_search: null
      }
    },
    mounted() {
      this.getCounts()
      if (this.isShort) {
        this.gon_search = gon.search
        this.current_tab = this.gon_search.scope
      }
    },
    methods: {
      getCounts() {
        let _this = this;
        const headers = {'X-Requested-With' : 'XMLHttpRequest','Content-Type': 'application/json'};
        fetch('/counts', {
          method: 'GET',
          headers:headers
        })
            .then(response => response.json())
            .then(function(data) {
              _this.counts = data
            })
            .catch((error) => {
              _this.error = error;
              _this.saving = false;
            });
      },
      changeTab(tab) {
        this.current_tab = tab;
      }
    },
    computed: {
      isShort() {
        return this.short_version === 'yes'
      }
    }
  }
</script>

<style lang="scss">

  .m-search-row {display: flex;flex-direction: column;}
  .m-search-group {margin-bottom:10px;};
  .m-search-group:first-child { margin-left: 0 }
  .m-search-group:last-child { margin-right: 0 }

  .price-search {min-width: 200px;}
  .area-search {min-width: 150px;}
  .year-search {min-width: 100px;}

  .front-mobile-search {
    background-color: white;
    padding: 10px;
    font-size: 14px;
    position: relative;
    width: 100%;
  }

  .m-search-tabs {
    display: flex;
    justify-content: space-between;
    .m-search-tab {
      width: 30%;
      display: flex;
      align-items: center;
      flex-direction: column;
      cursor: pointer;
      &:focus-visible {
        outline:none;
      }
      span {
        //margin-left: 10px;
        font-size: 14px;
        display: block;
        margin-bottom: 10px;
      }
      .fake-border {
        width: 100%;
        height: 5px;
        border-radius: 10px;
      }
      .fake-border.active {
        background-color: #115686;;
      }
      .c-active {
        color: #000;
      }
      .c-inactive {
        color: #70748C;
      }
    }
  }

  .search-form {
    padding-top: 18px;
  }

  .m-search-group {
    display: flex;
    flex-direction: column;
    label {
      font-weight: normal;
      font-size: 14px;
      color: #333333;
    }
  }

  .modal-wrapper {
    display: flex;
    flex-direction: column;
    padding: 20px;
  }

  .string-input {
    border: 1px solid #E0E0E0;
    height: 44px;
    padding: 0 16px;
    width: 100%;
    border-radius: 10px;
  }

  .offer_id {
    width: 120px;
    //margin-left: 16px;
  }

  .from-to {
    display: flex;
    .from-input, .to-input {
      border: 1px solid #E0E0E0;
      height: 44px;
      padding: 0 16px;
      width: 100%;
    }

    .from-input {
      border-radius: 10px 0 0 10px;
    }

    .to-input {
      border-left: none;
      border-radius: 0 10px 10px 0;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }
  }


</style>
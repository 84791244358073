<template>
  <div class="flats-selector">
    <button @click="toggleButton(flat)" class="flat-btn" v-for="(flat,index) in flats" :class="isSelected(flat) ? 'selected' : '' ">{{flat.title}}</button>
  </div>
</template>

<script>
  export default {
    props: {
      value: {
        type: Array,
        default: function () {
          return []
        }
      }
    },
    data: function () {
      return {
        flats: [{title:'Студия',val:'0'},{title:'1',val:'1'},{title:'2',val:'2'},{title:'3',val:'3'},{title:'4+',val:'4'}]
      }
    },
    mounted() {

    },
    methods: {
      toggleButton(btn) {
        const index = this.value.findIndex(el => el === btn.val)
        if (index !== -1) {
          let new_val = this.value.filter(e => e !== btn.val);
          this.$emit('input',new_val)
        } else {
          let new_val = this.value.concat([btn.val])
          this.$emit('input',new_val)
        }
      },
      isSelected(btn) {
        return this.value.includes(btn.val) //find(el => el.val === btn.val) !== undefined;
      }
    }
  }
</script>

<style lang="scss" scoped>
  .flats-selector {
    display: flex;
    button:first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      margin-left: unset;
    }
    button:last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }

  .flat-btn {
    cursor: pointer;
    height: 44px;
    min-width: 44px;
    color: #949494;
    border: 1px solid #d6dadc;
    background-color: transparent;
    margin-left: -1px;
    //z-index: 1;
    transition: color .3s,background-color .3s;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    z-index: 1;
    &:hover {
      border: 1px solid #115686;
      color: #115686;

      //background-color: #ffffff;
      z-index: 2;
    }
    &:focus {
      outline: none;
    }
  }

  .flat-btn.selected {
    border: 1px solid #115686;
    color: #115686;
    z-index: 2;
  }

</style>
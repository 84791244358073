
<div class="search-form">
  <div class="m-search-row">
    <div class="d-flex g-5">
      <div class="m-search-group w-50">
        <label>Тип недвижимости</label>
        <multi_select :data="data_info.house_kind" v-model="search_params['house_type']" @input="updateCount"/>
      </div>
      <div class="m-search-group price-search w-50">
        <label>Стоимость, ₽</label>
        <div class="from-to">
          <input v-debounce:600ms="updateCount" type="text" ref="from" v-model="search_params['price_from']" class="from-input" placeholder="От" v-currency="v_options" />
          <input v-debounce:600ms="updateCount" type="text" ref="from" v-model="search_params['price_to']" class="to-input" placeholder="До"  v-currency="v_options" />
        </div>
      </div>
    </div>

    <div class="d-flex g-5">
      <div class="m-search-group area-search w-50">
        <label>Площадь, м2</label>
        <div class="from-to">
          <input v-debounce:600ms="updateCount" type="text" ref="from" v-model="search_params['s_all_from']" class="from-input" placeholder="От" v-currency="v_options" />
          <input v-debounce:600ms="updateCount" type="text" ref="from" v-model="search_params['s_all_to']" class="to-input" placeholder="До"  v-currency="v_options" />
        </div>
      </div>
      <div class="m-search-group area-search w-50">
        <label>Площадь участка</label>
        <div class="from-to">
          <input v-debounce:600ms="updateCount" type="text" ref="from" v-model="search_params['lot_size_from']" class="from-input" placeholder="От" v-currency="v_options" />
          <input v-debounce:600ms="updateCount" type="text" ref="from" v-model="search_params['lot_size_to']" class="to-input" placeholder="До"  v-currency="v_options" />
        </div>
      </div>
    </div>


    <div class="d-flex g-5">
      <div class="m-search-group w-50">
        <label>Нас. пункт</label>
        <multi_select :data="search_data.locations.houses" v-model="search_params['locations']" @input="updateCount" />
      </div>
      <div class="m-search-group w-50">
        <label>Код объекта</label>
        <input v-model="search_params['id']" type="text" class="string-input offer_id w-100">
      </div>
    </div>

    <div class="m-search-group w-100">
      <filter-button @clack="showAdvanced"/>
    </div>
  </div>

  <div class="d-flex">
    <button class="btn btn-danger m-l-20" @click="clear">Очистить</button>
    <button class="btn btn-primary m-l-20" @click="goToResults">{{data_info.searchButtonCount(count)}}</button>
  </div>

  <modal name="houses-advanced" :width="900" :height="'auto'" :styles="'overflow: inherit'" :adaptive="true">

    <div class="modal-wrapper">
      <div class="modal-body">

        <div class="m-search-row">
          <div class="d-flex g-5">
            <div class="m-search-group w-50">
              <label>Комнат</label>
              <div class="from-to">
                <input v-debounce:600ms="updateCount" type="text" ref="from" v-model="search_params['house_rooms_from']" class="from-input" placeholder="От" v-currency="v_options" />
                <input v-debounce:600ms="updateCount" type="text" ref="from" v-model="search_params['house_rooms_to']" class="to-input" placeholder="До"  v-currency="v_options" />
              </div>
            </div>
            <div class="m-search-group w-50">
              <label>Жилая площадь</label>
              <div class="from-to">
                <input v-debounce:600ms="updateCount" type="text" ref="from" v-model="search_params['s_l_from']" class="from-input" placeholder="От" v-currency="v_options" />
                <input v-debounce:600ms="updateCount" type="text" ref="from" v-model="search_params['s_l_to']" class="to-input" placeholder="До"  v-currency="v_options" />
              </div>
            </div>
          </div>
          <div class="d-flex g-5">
            <div class="m-search-group w-50">
              <label>Этажей</label>
              <div class="from-to">
                <input v-debounce:600ms="updateCount" type="text" ref="from" v-model="search_params['total_floors_from']" class="from-input" placeholder="От" v-currency="v_options" />
                <input v-debounce:600ms="updateCount" type="text" ref="from" v-model="search_params['total_floors_to']" class="to-input" placeholder="До"  v-currency="v_options" />
              </div>
            </div>
            <div class="m-search-group w-50">
              <label>Состояние</label>
              <multi_select :data="data_info.conditions" :hide_filter="'yes'" v-model="search_params['conditions']" @input="updateCount"/>
            </div>
          </div>
        </div>

        <div class="d-flex g-5">
          <div class="m-search-group w-50">
            <label>Санузел</label>
            <multi_select :data="data_info.wc" :hide_filter="'yes'" v-model="search_params['wc']" @input="updateCount"/>
          </div>
          <div class="m-search-group w-50">
            <label>Балкон</label>
            <multi_select :data="data_info.balcony" :hide_filter="'yes'" v-model="search_params['balcony']" @input="updateCount"/>
          </div>
        </div>

        <div class="d-flex g-5">
          <div class="m-search-group w-50">
            <label>Отопление</label>
            <multi_select :data="data_info.heating" :hide_filter="'yes'" v-model="search_params['heating']" @input="updateCount"/>
          </div>
          <div class="m-search-group w-50">
            <label>Площадь кухни</label>
            <div class="from-to">
              <input v-debounce:600ms="updateCount" type="text" ref="from" v-model="search_params['s_k_from']" class="from-input" placeholder="От" v-currency="v_options" />
              <input v-debounce:600ms="updateCount" type="text" ref="from" v-model="search_params['s_k_to']" class="to-input" placeholder="До"  v-currency="v_options" />
            </div>
          </div>
        </div>
      </div>

      <div class="modal-bottom">
        <div class="w-100 frc">
          <button class="btn btn-primary m-l-20" @click="goToResults">{{data_info.searchButtonCount(count)}}</button>
          <button class="btn btn-danger" @click="clear">Очистить</button>
        </div>
      </div>
    </div>
  </modal>

</div>

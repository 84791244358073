
<div class="search-form">
  <div class="search-row">
    <div class="search-group">
      <label>Количество комнат</label>
      <flats_input v-model="search_params['rooms']" ref="flatsRooms" @input="updateCount"/>
    </div>
    <div class="search-group price-search">
      <label>Стоимость, ₽</label>
      <div class="from-to">
        <input v-debounce:600ms="updateCount" type="text" ref="from" v-model="search_params['price_from']" class="from-input" placeholder="От" v-currency="v_options" />
        <input v-debounce:600ms="updateCount" type="text" ref="from" v-model="search_params['price_to']" class="to-input" placeholder="До"  v-currency="v_options" />
      </div>
    </div>
    <div class="search-group area-search">
      <label>Площадь, м2</label>
      <div class="from-to">
        <input v-debounce:600ms="updateCount" type="text" ref="from" v-model="search_params['s_all_from']" class="from-input" placeholder="От" v-currency="v_options" />
        <input v-debounce:600ms="updateCount" type="text" ref="from" v-model="search_params['s_all_to']" class="to-input" placeholder="До"  v-currency="v_options" />
      </div>
    </div>
    <div class="search-group w-100">
      <label>Нас. пункт</label>
      <multi_select :data="search_data.locations.flats" v-model="search_params['locations']" @input="updateCount"/>
    </div>
    <div class="search-group w-100">
      <label>Улица</label>
      <multi_select :data="search_data.streets.flats" v-model="search_params['streets']" @input="updateCount"/>
    </div>
    <div class="search-group w-100">
      <label>Жилой комплекс</label>
      <multi_select :data="search_data.nbs" v-model="search_params['jk']" @input="updateCount"/>
    </div>
  </div>
  <div class="search-row">
    <div class="search-group">
      <input v-debounce:600ms="updateCount" v-model="search_params['id']" type="text" class="string-input offer_id" placeholder="Код объекта">
    </div>
    <div class="search-group">
      <single_select v-model="search_params['sort']" :options="[{title:'Сортировка',val:null},{title:'Сначала дешевле',val:'price_up'},{title:'Сначала дороже',val:'price_down'}]" />
    </div>

    <div class="w-100 frc">
      <button class="btn btn-danger m-l-20" @click="clear">Очистить</button>
      <button class="btn btn-primary m-l-20" @click="goToResults">{{data_info.searchButtonCount(count)}}</button>
      <filter-button @clack="showAdvanced"/>
    </div>
  </div>
  <modal name="flats-advanced" :width="900" :height="'auto'" :styles="'overflow: inherit'">

    <div class="modal-wrapper">
      <div class="modal-body">

        <div class="row">
          <div class="col-sm-4">
            <div class="search-group">
              <label>Год постройки</label>
              <div class="from-to">
                <input v-debounce:600ms="updateCount" type="text" ref="from" v-model="search_params['year_from']" class="from-input" placeholder="От" />
                <input v-debounce:600ms="updateCount" type="text" ref="from" v-model="search_params['year_to']" class="to-input" placeholder="До" />
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="search-group">
              <label>Этаж</label>
              <div class="from-to">
                <input v-debounce:600ms="updateCount" type="text" ref="from" v-model="search_params['floor_from']" class="from-input" placeholder="От" v-currency="v_options" />
                <input v-debounce:600ms="updateCount" type="text" ref="from" v-model="search_params['floor_to']" class="to-input" placeholder="До"  v-currency="v_options" />
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="search-group">
              <label>Этажей в доме</label>
              <div class="from-to">
                <input v-debounce:600ms="updateCount" type="text" ref="from" v-model="search_params['total_floors_from']" class="from-input" placeholder="От" v-currency="v_options" />
                <input v-debounce:600ms="updateCount" type="text" ref="from" v-model="search_params['total_floors_to']" class="to-input" placeholder="До"  v-currency="v_options" />
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-4">
            <div class="search-group w-100">
              <label>Состояние</label>
              <multi_select :data="data_info.conditions" :hide_filter="'yes'" v-model="search_params['conditions']" @input="updateCount"/>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="search-group w-100">
              <label>Санузел</label>
              <multi_select :data="data_info.wc" :hide_filter="'yes'" v-model="search_params['wc']" @input="updateCount"/>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="search-group w-100">
              <label>Балкон</label>
              <multi_select :data="data_info.balcony" :hide_filter="'yes'" v-model="search_params['balcony']" @input="updateCount"/>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-4">
            <div class="search-group w-100">
              <label>Отопление</label>
              <multi_select :data="data_info.heating" :hide_filter="'yes'" v-model="search_params['heating']" @input="updateCount"/>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="search-group">
              <label>Площадь кухни</label>
              <div class="from-to">
                <input v-debounce:600ms="updateCount" type="text" ref="from" v-model="search_params['s_k_from']" class="from-input" placeholder="От" v-currency="v_options" />
                <input v-debounce:600ms="updateCount" type="text" ref="from" v-model="search_params['s_k_to']" class="to-input" placeholder="До"  v-currency="v_options" />
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="search-group">
              <label>Высота потолков</label>
              <div class="from-to">
                <input v-debounce:600ms="updateCount" type="text" ref="from" v-model="search_params['ceiling_height_from']" class="from-input" placeholder="От" v-currency="v_options" />
                <input v-debounce:600ms="updateCount" type="text" ref="from" v-model="search_params['ceiling_height_to']" class="to-input" placeholder="До"  v-currency="v_options" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-bottom">
        <div class="w-100 frc">
          <button class="btn btn-primary m-l-20" @click="goToResults">{{data_info.searchButtonCount(count)}}</button>
          <button class="btn btn-danger" @click="clear">Очистить</button>
        </div>
      </div>
    </div>
  </modal>
</div>

<!--https://sfc.vuejs.org/#eyJBcHAudnVlIjoiPHNjcmlwdCBzZXR1cD5cbmltcG9ydCB7IHJlZiB9IGZyb20gJ3Z1ZSdcblxuY29uc3QgZG9jU3RhdGUgPSByZWYoJ3NhdmVkJylcbjwvc2NyaXB0PlxuXG48dGVtcGxhdGU+XG5cdDxzcGFuIHN0eWxlPVwibWFyZ2luLXJpZ2h0OiAyMHB4XCI+Q2xpY2sgdG8gY3ljbGUgdGhyb3VnaCBzdGF0ZXM6PC9zcGFuPlxuICA8ZGl2IGNsYXNzPVwiYnRuLWNvbnRhaW5lclwiPlxuXHRcdDxUcmFuc2l0aW9uIG5hbWU9XCJzbGlkZS11cFwiPlxuICAgICAgPGJ1dHRvbiB2LWlmPVwiZG9jU3RhdGUgPT09ICdzYXZlZCdcIlxuICAgICAgICAgICAgICBAY2xpY2s9XCJkb2NTdGF0ZSA9ICdlZGl0ZWQnXCI+RWRpdDwvYnV0dG9uPlxuICAgICAgPGJ1dHRvbiB2LWVsc2UtaWY9XCJkb2NTdGF0ZSA9PT0gJ2VkaXRlZCdcIlxuICAgICAgICAgICAgICBAY2xpY2s9XCJkb2NTdGF0ZSA9ICdlZGl0aW5nJ1wiPlNhdmU8L2J1dHRvbj5cbiAgICAgIDxidXR0b24gdi1lbHNlLWlmPVwiZG9jU3RhdGUgPT09ICdlZGl0aW5nJ1wiXG4gICAgICAgICAgICAgIEBjbGljaz1cImRvY1N0YXRlID0gJ3NhdmVkJ1wiPkNhbmNlbDwvYnV0dG9uPlxuICAgIDwvVHJhbnNpdGlvbj5cbiAgPC9kaXY+XG48L3RlbXBsYXRlPlxuXG48c3R5bGU+XG4uYnRuLWNvbnRhaW5lciB7XG4gIGRpc3BsYXk6IGlubGluZS1ibG9jaztcbiAgcG9zaXRpb246IHJlbGF0aXZlO1xuICBoZWlnaHQ6IDFlbTtcbn1cblxuYnV0dG9uIHtcbiAgcG9zaXRpb246IGFic29sdXRlO1xufVxuXG4uc2xpZGUtdXAtZW50ZXItYWN0aXZlLFxuLnNsaWRlLXVwLWxlYXZlLWFjdGl2ZSB7XG4gIHRyYW5zaXRpb246IGFsbCAwLjI1cyBlYXNlLW91dDtcbn1cblxuLnNsaWRlLXVwLWVudGVyLWZyb20ge1xuICBvcGFjaXR5OiAwO1xuICB0cmFuc2Zvcm06IHRyYW5zbGF0ZVgoMTBweCk7XG59XG5cbi5zbGlkZS11cC1sZWF2ZS10byB7XG4gIG9wYWNpdHk6IDA7XG4gIHRyYW5zZm9ybTogdHJhbnNsYXRlWCgtMTBweCk7XG59XG48L3N0eWxlPiIsImltcG9ydC1tYXAuanNvbiI6IntcbiAgXCJpbXBvcnRzXCI6IHtcbiAgICBcInZ1ZVwiOiBcImh0dHBzOi8vc2ZjLnZ1ZWpzLm9yZy92dWUucnVudGltZS5lc20tYnJvd3Nlci5qc1wiLFxuICAgIFwidnVlL3NlcnZlci1yZW5kZXJlclwiOiBcImh0dHBzOi8vc2ZjLnZ1ZWpzLm9yZy9zZXJ2ZXItcmVuZGVyZXIuZXNtLWJyb3dzZXIuanNcIlxuICB9XG59In0=-->
<template>
  <div class="offer-photo-show" @keydown.esc="closeFull">
    <div class="slide-control left-control">
      <img src="/front/circle-arrow-left.png" @click="scrollLeft" v-if="scrollAmount > 0">
    </div>
    <div class="slide-control right-control">
      <img src="/front/circle-arrow-right.png" @click="scrollRight" v-if="scrollAmount < scrollMax">
    </div>
    <div class="image-wrapper">
      <div class="row">
        <div class="col-sm-6" style="padding-right: 4px;padding-left: 4px;">
          <div class="first-photo" :style="`background-image: url(${main_image.full});`" @click="openFull(0)"></div>
        </div>
        <div class="col-sm-3 slider-col" v-for="image_group in groupedImages">
          <img class="w-100" v-for="image in image_group" :src="image.thumb" @click="openFull(imageIndex(image.thumb))" />
        </div>
      </div>
    </div>
    <button @click="openFull(0)" class="full-starter">{{info.fullScreenButtonCount(images.length)}}</button>
    <full-screen v-if="full_screen" :images="allImages" :offer_info="offer_info" @close="closeFull" :start_index="full_start_index" />
  </div>
</template>

<script>
  import info from "../search/data";
  import fullScreen from './full_screen.vue'
  export default {
    props: ['main_image','images','offer_info'],
    components: {fullScreen},
    data: function() {
      return {
        scrollAmount: 0,
        scrollMin: 0,
        container: null,
        info: info,
        full_screen: false,
        agent: {},
        full_start_index: 0
      }
    },
    mounted() {
      this.container = document.querySelector('.image-wrapper')
    },
    methods: {
      imageIndex(thumb_url) {
        var index = this.images.findIndex(img => img.thumb === thumb_url);
        return index+1
      },
      closeFull() {
        this.full_screen = false;
      },
      openFull(index) {
        this.full_start_index = index;
        this.full_screen = true;
      },
      scrollRight() {
        if (this.scrollAmount + this.scrollStep + 100 < this.scrollMax) {
          this.scrollAmount += this.scrollStep;
        } else {
          this.scrollAmount = this.scrollMax;
        }
        //console.log(this.scrollAmount);
        this.container.scrollTo({
          top: 0,
          left: this.scrollAmount,
          behavior: 'smooth'
        });
      },
      scrollLeft() {
        if (this.scrollAmount - this.scrollStep - 100 > this.scrollMin) {
          this.scrollAmount -= this.scrollStep;
        } else {
          this.scrollAmount = this.scrollMin;
        }
        //console.log(this.scrollAmount);
        this.container.scrollTo({
          top: 0,
          left: this.scrollAmount,
          behavior: 'smooth'
        });
      },
      splitArray(n, data) {
        let result = [];
        for (let i = 0; i < data.length; i += n) result.push(data.slice(i, i + n));
        return result;
      }
    },
    computed: {
      allImages() {
        return [this.main_image].concat(this.images)
      },
      groupedImages() {
        return this.splitArray(2,this.images)
      },
      scrollStep() {
        if (this.container === null) {
          return 0
        } else {
          return this.container.clientWidth/2
        }

      },
      scrollMax() {
        if (this.container === null) {
          return 0
        } else {
          return this.container.querySelector('.row').scrollWidth - this.container.clientWidth
        }
      }
    }
  }
</script>

<style lang="scss">
  .offer-photo-show {
    margin: 0 0 20px 0;
    position: relative;

    .image-wrapper {
      overflow-x: hidden;
      width: 100%;
      img {
      border-radius: 10px;
    }
    }
    .row {
      flex-wrap: nowrap;
      margin-right: -4px;
      margin-left: -4px;
    }
    .first-photo {
      border-radius: 10px;
      background-repeat:no-repeat;
      background-position: center center;
      height: 100%;
      display: flex;
      width: 100%;
    }
    .slider-col {
      padding-right: 4px;
      padding-left: 4px;
      img {
        cursor: pointer;
      }
      img:nth-child(1) {
        margin-bottom: 10px;
      }
    }

    .slide-control {
      position: absolute;
      display: flex;
      height: 100%;
      align-items: center;
      z-index: 1;
      img {
        cursor: pointer;
        width: 40px;
        height: 40px;
      }
    }

    .left-control {
      top:0;
      left:-20px;
    }
    .right-control {
      top: 0;
      right:-20px;
    }

    .full-starter {
      position: absolute;
      bottom: 10px;
      left: 10px;
      border: none;
      outline:none;
      border-radius: 8px;
      display: flex;
      height: 35px;
      align-items: center;
      cursor: pointer;

      padding: 0 16px;
      background-color: rgba(255,255,255,0.9);
      color: #000000;
    }

  }
</style>

  <div v-if="counts === null" class="front-search">
    <div class="text-center">
      Загрузка ...
    </div>
  </div>
  <div v-else class="front-search" :class="isShort ? 'short' : ''">
    <div class="search-tabs">
      <div class="search-tab" @click="changeTab('flats')">
        <span :class="current_tab==='flats' ? 'c-active' : 'c-inactive'">Квартиры</span>
        <div class="fake-border" :class="current_tab==='flats' ? 'active' : ''">&nbsp;</div>
      </div>
<!--      <div class="search-tab" @click="changeTab('nb')">-->
<!--        <span>Новостройки</span>-->
<!--        <div class="fake-border" :class="current_tab==='nb' ? 'active' : ''">&nbsp;</div>-->
<!--      </div>-->
      <div class="search-tab" @click="changeTab('houses')">
        <span :class="current_tab==='houses' ? 'c-active' : 'c-inactive'">Дома и участки</span>
        <div class="fake-border" :class="current_tab==='houses' ? 'active' : ''">&nbsp;</div>
      </div>
      <div class="search-tab" @click="changeTab('commercial')">
        <span :class="current_tab==='commercial' ? 'c-active' : 'c-inactive'">Коммерческая</span>
        <div class="fake-border" :class="current_tab==='commercial' ? 'active' : ''">&nbsp;</div>
      </div>
    </div>

    <flats-form v-show="current_tab === 'flats'" :counts="counts" :gon_search="gon_search"/>
<!--    <nb-form v-show="current_tab === 'nb'" :counts="counts" :gon_search="gon_search" />-->
    <houses-form v-show="current_tab === 'houses'" :counts="counts" :gon_search="gon_search" />
    <commercial-form v-show="current_tab === 'commercial'" :counts="counts" :gon_search="gon_search" />


  </div>

<template>
  <div class="from-to">
    <input v-if="name === 'year'" v-debounce:600ms="update" type="text" ref="from" v-model="from" class="from-input" placeholder="От" />
    <input v-else v-debounce:600ms="update" type="text" ref="from" v-model="from" class="from-input" placeholder="От" v-currency="v_options" />
    <input v-if="name === 'year'" v-debounce:600ms="update" type="text" ref="to" v-model="to" class="to-input" placeholder="До" />
    <input v-else v-debounce:600ms="update" type="text" ref="to" v-model="to" class="to-input" placeholder="До" v-currency="v_options" />
  </div>
</template>

<script>
  import { setValue,getValue,parse } from "vue-currency-input";
  export default {
    props: ['name','init_data','value'],
    components: { },
    data: function () {
      return {
        from: null,
        to: null,
        v_options: {
          currency:null,
          locale:'sv-SE',
          precision: 0,
          allowNegative: false,
          distractionFree: false
        }
      }
    },
    methods: {
      update() {
        this.$emit('updateParams',[
            {name:`${this.name}_from`,val:parse(this.from, this.v_options)},
            {name:`${this.name}_to`,val:parse(this.to, this.v_options)}
        ])
        // this.$emit('updateParams',{name:`${this.name}_to`,val:parse(this.to, this.v_options)})
      }
    },
    watch: {
      init_data : function(data) {
        console.log(data);
      }
    }
  }
</script>

<style lang="scss" scoped>
  .from-to {
    display: flex;
    .from-input, .to-input {
      border: 1px solid #E0E0E0;
      height: 44px;
      padding: 0 16px;
      width: 100%;
    }

    .from-input {
      border-radius: 10px 0 0 10px;
    }

    .to-input {
      border-left: none;
      border-radius: 0 10px 10px 0;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }
  }



</style>
<template>
    <span class="search-filter" @click="klick">
      <svg width="14px" height="14px" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M472,168H40a24,24,0,0,1,0-48H472a24,24,0,0,1,0,48Z"/><path d="M392,280H120a24,24,0,0,1,0-48H392a24,24,0,0,1,0,48Z"/><path d="M296,392H216a24,24,0,0,1,0-48h80a24,24,0,0,1,0,48Z"/></svg>&nbsp;Расширенный&nbsp;поиск
    </span>
</template>

<script>
  export default {
    methods: {
      klick() {
        this.$emit('clack',{})
      }
    }
  }
</script>

<style lang="scss" scoped>
  .search-filter {
    cursor: pointer;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 12px;
    border-radius: 4px;
    &:hover {
      background-color: #d4e5fa;
    }
  }
</style>
export default {
    commercial_wc: [
        {val: 'personal', title:'свой'},
        {val: 'shared', title:'общий'},
        {val: 'service', title:'служебный'},
        {val: 'false', title:'нет'}
    ],
    commercial_facade: [
        {val: 'street', title:'на улицу'},
        {val: 'yard', title:'во двор'},
        {val: 'mall', title:'внутри тц'}
    ],
    commercial_entrance: [
        {val: 'personal', title:'отдельный'},
        {val: 'shared', title:'общий'}
    ],
    commercial_conditions: [
        {val: 'good', title:'ремонт'},
        {val: 'newborn', title:'предчистовая'},
    ],
    commercial_type: [
        {val: 'office', title:'офисное помещение'},
        {val: 'free', title:'помещение свободного назначения'},
        {val: 'production', title:'производственное помещение'},
        {val: 'warehouse', title:'складское помещение'},
        {val: 'trade', title:'торговое помещение'},
        {val: 'business', title:'готовый бизнес'},
        {val: 'hotels', title:'гостиницы'}
    ],
    house_kind: [
        {val: 'house', title:'дома'},
        {val: 'townhouse', title:'таунхаусы'},
        {val: 'dacha', title:'дачи'},
        {val: 'lot', title:'земельный участок'}
    ],
    conditions: [
        {val: 'black', title:'черновая отделка'},
        {val: 'newborn', title:'предчистовая отделка'},
        {val: 'partial', title:'частичная отделка'},
        {val: 'clean', title:'чистовая отделка'},
        {val: 'damaged', title:'требуется ремонт'},
        {val: 'default', title:'жилое'},
        {val: 'good', title:'с ремонтом'},
        {val: 'best', title:'евро-ремонт'},
    ],
    wc: [
        {val: 'solid', title:'совмещенный'},
        {val: 'split', title:'раздельный'},
        {val: '2', title:'2'},
        {val: '3', title:'3 и более'},
    ],
    balcony: [
        {val: 'no', title:'без балкона'},
        {val: 'b', title:'балкон'},
        {val: 'glass_b', title:'балкон застекленный'},
        {val: 'bl', title:'балкон и лоджия'},
        {val: 'glass_bl', title:'балкон и лоджия застеклены'},
        {val: 'l', title:'лоджия'},
        {val: 'glass_l', title:'лоджия застеклена'},
        {val: 'b2', title:'2 балкона'},
        {val: 'l2', title:'2 лоджии'}
    ],
    window_view: [
        {val: 'street', title:'на улицу'},
        {val: 'yard', title:'во двор'},
        {val: 'both ', title:'улица и двор'},
        {val: 'sea', title:'на море'}
    ],
    heating: [
        {val: 'central', title:'центральное'},
        {val: 'gas', title:'индивидуальное газовое'},
        {val: 'electric', title:'электрическое'}
    ],
    searchButtonCount: function(number) {
        let str = ''
        const digit = String(number).slice(-1);
        if (number === 0) {
            str = `Показать предложения`
        } else if (number === 11 || number === 12 || ['0','5','6','7','8','9'].includes(digit)) {
            str = `Показать ${number} предложений`
        } else if (['2','3','4'].includes(digit)) {
            str = `Показать ${number} предложения`
        } else {
            str = `Показать ${number} предложение`
        }
        return str
    },
    fullScreenButtonCount: function(number) {
        let str = ''
        const digit = String(number).slice(-1);
        if (number === 0) {
            str = `Показать фотографии`
        } else if (number === 11 || number === 12 || ['0','5','6','7','8','9'].includes(digit)) {
            str = `Показать ${number} фотографий`
        } else if (['2','3','4'].includes(digit)) {
            str = `Показать ${number} фотографии`
        } else {
            str = `Показать ${number} фотографию`
        }
        return str
    }
}

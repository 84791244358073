import Vue from 'vue/dist/vue.esm';
import VModal from 'vue-js-modal'

import frontSearch from '../components/front/search/search.vue';
import mobileFrontSearch from '../components/front/mobile_search/search.vue';
import offerPhotos from '../components/front/offer_photos/offer_photos.vue';
import agent from '../components/front/agent/agent.vue';
import { tns } from "tiny-slider/src/tiny-slider"

import VueCurrencyInput from "vue-currency-input";
import vueDebounce from 'vue-debounce';

Vue.use(VueCurrencyInput);
Vue.use(vueDebounce);
Vue.use(VModal);


document.addEventListener('DOMContentLoaded', () => {
    if (document.querySelector('.offer-mobile-slider') !== null) {
        document.querySelectorAll('.offer-mobile-slider').forEach((node) => {
            tns({
                container: node,
                items: 1,
                slideBy: 'page',
                nav: false,
                "mouseDrag": true,
                controls: false,
                controlsText: ['‹','›']
            });
        })
    }
    if (document.querySelector('.top-img-slider') !== null) {
        document.querySelectorAll('.top-img-slider').forEach((node) => {
            console.log('here!')
            tns({
                container: node,
                items: 1,
                slideBy: 'page',
                nav: false,
                "mouseDrag": true,
                controls: false,
                controlsText: ['‹','›'],
                autoplay: true,
                autoplayTimeout: 7000,
                autoplayButton: false,
                autoplayButtonOutput: false,
            });
        })
    }


    if (document.querySelector('#reviews') !== null) {
        document.querySelectorAll('#reviews').forEach((node) => {
            tns({
                container: node,
                items: 1,
                slideBy: 'page',
                autoplay: true,
                nav: true,
                "mouseDrag": true,
                controls: false,
                controlsText: ['‹','›'],
                autoplayButton: false,
                autoplayButtonOutput: false,
                autoplayPosition: 'bottom'
            });
        })
    }

    const front_search = document.querySelector('#v-front-search')
    if (front_search !== null) {
        new Vue({
            render: h => h(frontSearch,{
                props:{
                    short_version:front_search.dataset.realtyVersion,
                }
            }),
        }).$mount(front_search)
    }

    const mobile_front_search = document.querySelector('#v-mobile-front-search')
    if (mobile_front_search !== null) {
        new Vue({
            render: h => h(mobileFrontSearch,{
                props:{
                    short_version:null,
                }
            }),
        }).$mount(mobile_front_search)
    }

    const offer_photos = document.querySelector('#v-offer-photos')
    if (offer_photos !== null) {
        new Vue({
            render: h => h(offerPhotos,{
                props:{
                    main_image: gon.main_image,
                    images: gon.images,
                    offer_info: gon.offer_info
                }
            }),
        }).$mount(offer_photos)
    }

    const agent_info = document.querySelector('#v-agent')
    if (agent_info !== null) {
        new Vue({
            render: h => h(agent,{
                props:{
                    agent: gon.offer_info.agent
                }
            }),
        }).$mount(agent_info)
    }
});

import Rails from "@rails/ujs"
if (typeof Rails === 'undefined') {
    Rails.start()
}


<div class="full-screen-photos" @keydown.esc="close" tabindex="0">
  <div class="photo-gallery">
    <div class="big-photo">
      <div class="slide-control left-control" v-if="isCanPrev">
        <img src="/front/circle-arrow-left.png" @click="prevPhoto">
      </div>
      <img :src="currentPhoto" />
      <div class="slide-control right-control" v-if="isCanNext">
        <img src="/front/circle-arrow-right.png" @click="nextPhoto">
      </div>
    </div>
    <div class="thumbnails">
      <img :src="image.thumb" v-for="(image,index) in images" style="height:50px;" @click="selectPhoto(index)" :class="index === current_index ? 'selected' : ''">
    </div>
    <div class="photo-counter">
      {{current_index+1}} / {{images.length}}
    </div>
  </div>
  <div class="agent-block">
    <div class="m-b-15" style="display: flex;justify-content: space-between">
      <span class="agent-title">{{offer_info.title}}</span>
      <span class="close-button" @click="close">✕</span>
    </div>
    <div class="m-b-25">{{offer_info.address}}</div>
    <div class="m-b-15">{{offer_info.human_price}}<span class="sqr-price">{{offer_info.sqr_price}}</span></div>
    <agent :agent="offer_info.agent" />
  </div>

</div>

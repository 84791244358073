<template>
  <div class="multi-pulti">
    <div class="selected-block" @click="toggle">
      <span class="selected-txt">
        {{selectedCount > 0 ? `Выбрано: ${selectedCount}` : 'Выбрать'}}
      </span>

      <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" :class="opened ? 'rotated' : ''">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.21967 9.21967C4.92678 9.51256 4.92678 9.98744 5.21967 10.2803L11.4697 16.5303C11.7626 16.8232 12.2374 16.8232 12.5303 16.5303L18.7803 10.2803C19.0732 9.98744 19.0732 9.51256 18.7803 9.21967C18.4874 8.92678 18.0126 8.92678 17.7197 9.21967L12 14.9393L6.28033 9.21967C5.98744 8.92678 5.51256 8.92678 5.21967 9.21967Z" fill="#4F4F4F"/>
      </svg>

    </div>
    <div class="select-list" v-click-outside="hide" v-show="opened">
      <div class="select-filter" v-if="hide_filter !== 'yes'">
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.75 7H7.355L7.215 6.865C7.705 6.295 8 5.555 8 4.75C8 2.955 6.545 1.5 4.75 1.5C2.955 1.5 1.5 2.955 1.5 4.75C1.5 6.545 2.955 8 4.75 8C5.555 8 6.295 7.705 6.865 7.215L7 7.355V7.75L9.5 10.245L10.245 9.5L7.75 7ZM4.75 7C3.505 7 2.5 5.995 2.5 4.75C2.5 3.505 3.505 2.5 4.75 2.5C5.995 2.5 7 3.505 7 4.75C7 5.995 5.995 7 4.75 7Z" fill="#838C91"/>
        </svg>
        <input type="text" placeholder="Поиск" v-model="filter" />
      </div>
      <div class="select-items">
        <div v-for="(item,index) in filteredData" class="select-item" @click="toggleSelect(item.val)" :class="value.includes(item.val) ? 'selected' : '' ">
          {{item.title}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ClickOutside from 'vue-click-outside'

  export default {
    // props: ['value','name', 'data','hide_filter'],
    props: {
      value: {
        type: Array,
        default: function () {
          return []
        }
      },
      name: {
        type: String
      },
      data: {
        type: Array
      },
      hide_filter: {
        type: String
      }
    },
    data: function () {
      return {
        opened: false,
        selected: [],
        filter:''

      }
    },
    mounted() {
      // prevent click outside event with popupItem.
      this.popupItem = this.$el
    },
    methods: {
      hide() {
        this.opened = false
      },
      toggle() {
        this.opened = !this.opened
      },
      toggleSelect(val) {
        let index = this.value.indexOf(val);
        if (index !== -1) {
          let new_val = this.value.filter(e => e !== val);
          this.$emit('input',new_val)
        } else {
          let new_val = this.value.concat([val])
          this.$emit('input',new_val)
        }
      }
    },
    computed: {
      filteredData() {
        if (this.filter !== '') {
          return this.data.filter(item => {
            return item.title.toLowerCase().indexOf(this.filter.toLowerCase()) > -1
          })
        } else {
          return this.data
        }
      },
      selectedCount() {
        return this.value.length
      }
    },
    directives: {
      ClickOutside
    }
  }
</script>

<style lang="scss" scoped>
  .multi-pulti {
    position: relative;
  }

  svg {
    transition: transform .2s ease-in-out;
  }

  .rotated {
    transform: rotate(180deg);
  }

  .selected-block {
    cursor: pointer;
    border: 1px solid #E0E0E0;
    height: 44px;
    padding: 0 16px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .select-list {
    position: absolute;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border: 1px solid rgba(0,0,0,0.25);
    border-radius: 4px;
    //box-shadow: 0 5px 15px calc(rgb(0 0 0 / 6%));
    box-shadow: 0 5px 15px rgba(0,0,0,0.6);
    user-select: none;
    //bottom: 10px;
    margin-top: 10px;
    max-height: 225px;
    min-width: 100%;
    overflow-x: hidden;
    width: 250px;
    z-index: 10;
  }

  .select-list::-webkit-scrollbar {
    //background-color: #FFFFFF;
    background-color: transparent;
    width: 5px;
  }

  .select-list::-webkit-scrollbar-thumb {
    background: #cccccc;;
    border-radius: 5px;
  }



  .select-filter {
    padding: 5px;
    display: flex;
    align-items: center;
    max-height: 200px;
    input[type=text] {
      border: none;
      outline: none;
      width: 100%;
    }
  }

  .select-item {
    padding: 5px;
    cursor: pointer;
    &:hover {
      background-color: #115686;;
      color: white;
    }
  }

  .select-item.selected {
    background-color: #115686;
    color: white;
  }

</style>

<div class="offer-photo-show" @keydown.esc="closeFull">
  <div class="slide-control left-control">
    <img src="/front/circle-arrow-left.png" @click="scrollLeft" v-if="scrollAmount > 0">
  </div>
  <div class="slide-control right-control">
    <img src="/front/circle-arrow-right.png" @click="scrollRight" v-if="scrollAmount < scrollMax">
  </div>
  <div class="image-wrapper">
    <div class="row">
      <div class="col-sm-6" style="padding-right: 4px;padding-left: 4px;">
        <div class="first-photo" :style="`background-image: url(${main_image.full});`" @click="openFull(0)"></div>
      </div>
      <div class="col-sm-3 slider-col" v-for="image_group in groupedImages">
        <img class="w-100" v-for="image in image_group" :src="image.thumb" @click="openFull(imageIndex(image.thumb))" />
      </div>
    </div>
  </div>
  <button @click="openFull(0)" class="full-starter">{{info.fullScreenButtonCount(images.length)}}</button>
  <full-screen v-if="full_screen" :images="allImages" :offer_info="offer_info" @close="closeFull" :start_index="full_start_index" />
</div>

<template>
  <div class="single-select">
    <div class="selected-block" @click="toggle">
      <span class="selected-txt">
        {{title}}
      </span>
      <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" :class="opened ? 'rotated' : ''">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.21967 9.21967C4.92678 9.51256 4.92678 9.98744 5.21967 10.2803L11.4697 16.5303C11.7626 16.8232 12.2374 16.8232 12.5303 16.5303L18.7803 10.2803C19.0732 9.98744 19.0732 9.51256 18.7803 9.21967C18.4874 8.92678 18.0126 8.92678 17.7197 9.21967L12 14.9393L6.28033 9.21967C5.98744 8.92678 5.51256 8.92678 5.21967 9.21967Z" fill="#4F4F4F"/>
      </svg>
    </div>
    <div class="select-list" v-click-outside="hide" v-show="opened">
      <div class="select-items">
        <div v-for="(item,index) in options" class="select-item" @click="toggleSelect(item)" :class="value === item.val ? 'selected' : '' ">
          {{item.title}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ClickOutside from 'vue-click-outside'
  export default {
    props: {
      value: {
        type: String,
        default: ''
      },
      name: {
        type: String
      },
      options: {
        type: Array
      }
    },
    data: function () {
      return {
        opened: false,
        placeholder: ''
      }
    },
    mounted() {
      // prevent click outside event with popupItem.
      this.popupItem = this.$el
      // if (this.value === '') {
      //   this.placeholder = this.options[0].title
      // } else {
      //   this.placeholder = this.options.filter( d => d.val === this.value)[0]?.title
      // }

    },
    methods: {
      hide() {
        this.opened = false
      },
      toggle() {
        this.opened = !this.opened
      },
      toggleSelect(item) {
        this.placeholder = item.title;
        this.$emit('input',item.val)
        this.hide()
      }
    },
    computed: {
      title() {
        if (this.value === '') {
          return this.options[0].title
        } else {
          return this.options.filter( d => d.val === this.value)[0]?.title
        }
      }
    },
    directives: {
      ClickOutside
    }
  }
</script>

<style lang="scss" scoped>
  .single-select {
    position: relative;
  }

  svg {
    transition: transform .2s ease-in-out;
  }

  .rotated {
    transform: rotate(180deg);
  }

  .selected-block {
    cursor: pointer;
    border: 1px solid #E0E0E0;
    height: 44px;
    padding: 0 16px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-wrap: nowrap;
  }

  .select-list {
    position: absolute;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border: 1px solid rgba(0,0,0,0.25);
    border-radius: 4px;
    //box-shadow: 0 5px 15px calc(rgb(0 0 0 / 6%));
    box-shadow: 0 5px 15px rgba(0,0,0,0.6);
    user-select: none;
    //bottom: 10px;
    margin-top: 10px;
    max-height: 225px;
    min-width: 100%;
    overflow-x: hidden;
    width: 250px;
    z-index: 10;
  }

  .select-list::-webkit-scrollbar {
    //background-color: #FFFFFF;
    background-color: transparent;
    width: 5px;
  }

  .select-list::-webkit-scrollbar-thumb {
    background: #cccccc;;
    border-radius: 5px;
  }

  .select-item {
    padding: 5px;
    cursor: pointer;
    &:hover {
      background-color: #115686;;
      color: white;
    }
  }

  .select-item.selected {
    background-color: #115686;
    color: white;
  }

</style>